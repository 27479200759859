import React from 'react'
import { graphql } from 'gatsby'
import { Event as EventTemplate } from '../components/templates'

const Event: React.FC<Props> = ({ data, location }) => {
  if (data?.contentfulEvent?.name && data?.contentfulEvent?.image?.gatsbyImageData) {
    return (
      <EventTemplate
        name={data.contentfulEvent.name}
        description={data.contentfulEvent?.description?.description}
        gatsbyImageData={data.contentfulEvent.image.gatsbyImageData}
        location={location}
      />
    )
  } else {
    return null
  }
}

export default Event

export const query = graphql`
  query EventQuery($slug: String!) {
    contentfulEvent(slug: { eq: $slug }) {
      name
      description {
        description
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, height: 1080)
      }
    }
  }
`

interface Props {
  data: {
    contentfulEvent: {
      name: string
      description?: {
        description?: string
      }
      image: {
        gatsbyImageData: any
      }
    }
  }
  location: any
}
